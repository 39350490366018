// avater-1.avif
import React from "react";
import Avatar1 from "../../../assets/images/Alexia-Patel.png";
import Avatar2 from "../../../assets/images/David-Chen.png";
import Avatar3 from "../../../assets/images/Emily-Sanders.png";
import Avatar4 from "../../../assets/images/Lisa-Rodriguez.png";
import Avatar5 from "../../../assets/images/Sophia-Lee.png";

const TrustedByFounders = () => {
  return (
    <div className="flex items-center space-x-2">
      {/* Avatar Group */}
      <div className="flex -space-x-3">
        {[Avatar1, Avatar2, Avatar3, Avatar4, Avatar5].map((avatar, index) => (
          <div key={index} className="relative group">
            <img
              src={avatar}
              alt={`Founder ${index + 1}`}
              className="w-8 md:w-10 h-8 md:h-10 rounded-full border-2 border-black"
            />
            {/* Tooltip */}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full bg-black text-white text-xs rounded-md py-1 px-2 hidden group-hover:block">
              David
            </div>
          </div>
        ))}
      </div>

      {/* Stars and Text */}
      <div className=" flex flex-col">
        <div className="flex -ml-[2px]">
          {/* Star icons can be from a library like Heroicons or FontAwesome */}
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className="w-[18px] h-[18px]"
              fill="black"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927C9.469 1.84 10.531 1.84 10.951 2.927L12.169 6.103L15.652 6.262C16.815 6.313 17.213 7.783 16.297 8.466L13.614 10.472L14.392 13.849C14.649 14.923 13.482 15.709 12.628 15.142L9.999 13.367L7.371 15.142C6.517 15.709 5.35 14.923 5.608 13.849L6.385 10.472L3.703 8.466C2.787 7.783 3.185 6.313 4.348 6.262L7.831 6.103L9.049 2.927Z" />
            </svg>
          ))}
        </div>
        <span className="text-[12px] font-medium">Trusted by busy professionals</span>
      </div>
    </div>
  );
};

export default TrustedByFounders;
