import React from "react";
import AIToolsCard from "../../cards/AIToolsCard";
import AI_IMAGE1 from "../../../assets/images/interactive-interface.png";
import AI_IMAGE2 from "../../../assets/images/fundamentals.png";
import AI_IMAGE3 from "../../../assets/images/progress-interface.png";
import AI_IMAGE4 from "../../../assets/images/modules-interface.gif";
import AI_IMAGE5 from "../../../assets/images/courses-interface.gif";
import RESULT_IMAGE from "../../../assets/images/module-vs-schedule.gif";

const DATA = [
  {
    id: 1,
    image: AI_IMAGE1,
    name: "Hands-On Coding from Day One",
    description:
      "Unlike passive tutorials, our courses are fully interactive. You'll write, run, and debug real code, mastering practical skills for real-world applications.",
  },
  {
    id: 2,
    image: AI_IMAGE2
    ,
    name: "Build Job-Ready Skills Fast",
    description:
      "Our courses are designed by developers who know what it takes to get hired. You'll gain the core skills that employers want, not just surface-level knowledge.",
  },
  {
    id: 3,
    image: AI_IMAGE3,
    name: "Track Your Results, Stay Motivated",
    description:
      "With clear progress tracking, you'll always know how close you are to your goals, giving you a sense of accomplishment after each lesson.",
  },
  {
    id: 4,
    image: AI_IMAGE4,
    name: "Challenges That Push You Forward",
    description:
      "Our coding challenges grow with your skills, so you're constantly improving and ready for the next step.",
  },
  {
    id: 5,
    image: AI_IMAGE5,
    name: "Personalized Support Whenever You Need It",
    description: "Stuck on a lesson? Get live help from experts during office hours or join a private community for instant support. Never get stuck or left behind.",
  },
];

export default function AllAIToolsSection() {
  return (
    <div>
      <div className="space-y-6 container px-4 mx-auto">
        {/* title */}
        <h1 className="display_Text text-center  ">
          Achieve Real Progress, Not Just Lessons
        {/* <br className="hidden md:flex " /> */}
        </h1>
        {/* sub */}
        <p className="body-custom text-center">
          We personalize your learning schedule based on how much time you can commit daily,
          <br className="hidden md:flex " /> so you can consistently move forward—even with a busy life.
        </p>
        <div className="flex justify-center items-center min-h-screen">
        <div className="flex justify-center max-w-[1104px] bg-[#F9FAFB] p-2 md:p-7 rounded-md shadow-lg md:shadow-sm">
          <img
            src={RESULT_IMAGE}
            alt="Hourandcode Lesson"
            className="w-full h-full rounded-lg shadow-sm"
          />
        </div>
      </div>
        {/* tools */}
        {/* <div className=" pt-8 md:pt-12">
          <div className="lg:px-10">
            <div className="grid lg:grid-cols-3 gap-8">
              <div className="col-span-1 lg:col-span-2">
                <AIToolsCard item={DATA?.[0]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[1]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[2]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[3]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[4]} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
