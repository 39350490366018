import React from "react";
import According from "../../according/According";
import posthog from 'posthog-js'; // Add this import

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "What is Hourandcode?",
    answer:
      "Hourandcode is a flexible online learning platform designed for busy professionals and students who want to master coding without sacrificing their daily responsibilities.",
  },
  {
    question: "How does Hourandcode work?",
    answer:
      "Hourandcode offers self-paced courses that fit into your schedule. The platform allows you to set your learning pace, choose between different course views, and engage in interactive lessons where you write and run code.",
  },
  {
    question: "What courses does Hourandcode offer?",
    answer:
    "Hourandcode offers interactive coding courses that cover HTML, CSS, Python, Rust, Golang, Ruby, SQL and more.",
},
  {
    question: "Can I learn on the go with Hourandcode?",
    answer:
      "Absolutely! Hourandcode is mobile-optimized, so you can access your courses from anywhere, making it easy to fit learning into your busy schedule.",
  },
  {
    question: "How fast can I build job-ready skills?",
    answer:
      "Our courses are designed by developers who understand what employers are looking for. You'll gain the core skills that tech companies need, helping you build a job-ready skill set quickly. We focus on practical, in-demand knowledge, not just surface-level information, so you’ll be prepared for interviews and real projects.",
  },
  {
    question: "What kind of challenges can I expect?",
    answer:
      "Our coding challenges are designed to grow with your skill level. As you advance through the course, the challenges will become more complex, pushing you to constantly improve and prepare for the next step in your coding journey.",
  },
  {
    question: "What kind of support is available if I get stuck?",
    answer:
      "We offer live expert support during office hours and access to a private community where you can ask questions anytime. You’ll never feel stuck or left behind because help is always available when you need it.",
  },
  {
    question: "Is there a free trial?",
    answer:
      "Yes, we offer a 7-day free trial. During the trial, you'll get full access to all course content, and your card won’t be charged until the trial period ends. This gives you the freedom to explore the platform and ensure it’s the right fit for you before committing.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "We offer a 7-day free trial, giving you full access without any charge. This allows you to explore the platform risk-free. If it’s not for you, feel free to cancel anytime during the trial period, and you won’t be billed. Once the subscription starts, we don't offer refunds, which helps us reduce fraud and keep things straightforward for everyone.",
  },
  {
    question: "Why Choose Us Over a Coding Bootcamp?",
    answer:
      "Our platform offers hands-on coding experience from day one, just like a bootcamp, but with more flexibility and at a fraction of the cost. You can work at your own pace, whether you want to learn part-time or dive in full-time.",
  },
  {
    question: "Do I need to quit my job to join?",
    answer:
      "No, unlike many coding bootcamps that require full-time commitment, our platform allows you to learn at your own pace. You can fit lessons around your existing schedule, whether that’s after work, during lunch, or on the weekends.",
  },
];

export default function FAQSection() {
  return (
    <div id="faq">
      <div className="space-y-14 container px-4 mx-auto pb-[50px] md:pb-[100px]  lg:pb-[130px]">
        <div className="space-y-7">
          <h1 className="display_Text text-center ">FAQ</h1>
          <p className="body-custom text-center ">Frequently Asked Questions</p>
        </div>
        {/* con */}
        <div className="max-w-2xl mx-auto my-10">
          {faqs.map((faq, index) => {
            return (
              <>
                <According key={index} faq={faq} />
              </>
            );
          })}
        </div>
        <div className="row">
          <div className="flex justify-center">
            <a href="https://app.hourandcode.com/authentication" className="button" onClick={() => posthog.capture('Bottom CTA')}>Get Started Today</a>
          </div>
        </div>
      </div>
    </div>
  );
}
