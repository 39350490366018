import React from "react";
import RattingCard from "../../cards/RattingCard";
import DAVID_CHEN from "../../../assets/images/David-Chen.png";
import EMILY_SANDERS from "../../../assets/images/Emily-Sanders.png";
import MIKE_THOMPSON from "../../../assets/images/Mike-Thompson.png";
import LISA_RODRIGUEZ from "../../../assets/images/Lisa-Rodriguez.png";
import SOPHIA_LEE from "../../../assets/images/Sophia-Lee.png";
import ALEXIA_PATEL from "../../../assets/images/Alexia-Patel.png";

export default function ClientSaySection() {
  return (
    <div id="testimonials">
      <div className="space-y-14 container px-4 mx-auto py-[60px]  md:py-[70px] lg:py-[80px]">
        <div className="space-y-7">
          <h1 className="display_Text text-center  ">
            Success Stories from Busy Learners
            {/* <br className="hidden md:flex " />  */}
          </h1>
          {/* sub */}
          <p className="body-custom text-center">
            Hear from professionals and students who have mastered coding on their own schedule,
            <br className="hidden md:flex " /> thanks to our interactive, expertly designed courses.
          </p>
        </div>

        {/* list */}
      </div>
      {/* client say section */}
      <div className="bg-slate-50 w-full px-2">
        {/*  */}
        <div className="space-y-14 container mx-auto py-[60px]  md:py-[70px] lg:py-[80px]">

          {/* list comp */}
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  gap-5 ">
            <RattingCard
              author="Lisa Rodriguez"
              job="Tech Consultant"
              imageURL={LISA_RODRIGUEZ}
              content="Hourandcode got me from zero to Ruby mastery in no time. Each lesson stuck with me. I could see myself getting better after every session, and that kind of progress is exactly what I needed."
            />
            <RattingCard
              author="Emily Sanders"
              job="Marketing Manager"
              imageURL={EMILY_SANDERS}
              content="I never thought I’d say this, but I’m building projects with my own hands. The lessons on Hourandcode are sharp and to the point. I pick up a new skill every time I log in."
            />
            <RattingCard
              author="Mike Thompson"
              job="Sales"
              imageURL={MIKE_THOMPSON}
              content="Coding felt like a mountain I’d never climb, but Hourandcode broke it down for me. I started small, and now I’ve got the tools to build my own startup."
            />
            <RattingCard
              author="Sophia Lee"
              job="Full-time Student"
              imageURL={SOPHIA_LEE}
              content="Hourandcode made coding fit into my crazy schedule. The lessons are quick, and the skills stick! I've been able to apply everything I learned without hesitation."
            />
            <RattingCard
              author="David Chen"
              job="Project Manager"
              imageURL={DAVID_CHEN}
              content="Balancing a job and learning to code always seemed impossible. But with Hourandcode, I found myself coding every day. You may think you don't have time to code, but you do."
            />
            <RattingCard
              author="Alexia Patel"
              job="Software Developer"
              imageURL={ALEXIA_PATEL}
              content="Wow! When I started, I didn’t expect to level up this fast. The structured paths on Hourandcode pushed me further than I thought possible, and I can confidently say that I CAN CODE now."
            />
          </div>
          {/* list comp */}
        </div>
      </div>
    </div>
  );
}
