import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';

interface Selections {
  step1: string | null;
  step2: string | null;
  step3: string | null;
}

interface Step3Option {
  level: string;
  description: string;
}

const QuizComponent: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selections, setSelections] = useState<Selections>({
    step1: null,
    step2: null,
    step3: null,
  });

  // Style for each step container
  const stepStyle = {
    backgroundColor: '#f4e5e5',
    borderRadius: '20px',
    padding: '30px',
  };

  // Handler functions for each step
  const handleStep1Selection = (selection: string) => {
    setSelections({ ...selections, step1: selection });
    posthog.capture('Quiz Step 1 Selection', { selection });
    setCurrentStep(2);
  };

  const handleStep2Selection = (selection: string) => {
    setSelections({ ...selections, step2: selection });
    posthog.capture('Quiz Step 2 Selection', { selection });
    setCurrentStep(3);
  };

  const handleStep3Selection = (selection: string) => {
    setSelections({ ...selections, step3: selection });
    posthog.capture('Quiz Step 3 Selection', { selection });
    setCurrentStep(4);
  };

  // Simulate loading for step 4
  useEffect(() => {
    if (currentStep === 4) {
      setTimeout(() => {
        setCurrentStep(5);
      }, 3000);
    }
  }, [currentStep]);

  // Function to go back to the previous step
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Function to reset the quiz
  const handleStartOver = () => {
    setCurrentStep(1);
    setSelections({ step1: null, step2: null, step3: null });
  };

  // Quiz options
  const step1Options: string[] = [
    'Web Development',
    'Computer Science',
    'Artificial Intelligence',
    'Game Development',
    'Data Visualization',
    'Data Science',
    'Web Design',
    'Machine Learning',
    'Mobile Development',
    'Not sure yet',
  ];

  const step2Options: string[] = [
    'Switch careers',
    'Learn a skill for my job',
    'Build a project',
    'Learn for school',
    'Learn for fun',
    'Not sure yet',
  ];

  const step3Options: Step3Option[] = [
    {
      level: 'Beginner',
      description: "I've never coded, or I only know the basics.",
    },
    {
      level: 'Intermediate',
      description: "I've created some projects with code.",
    },
    {
      level: 'Advanced',
      description: 'I have professional experience writing code.',
    },
  ];

  const skillsMapping: {
    [level: string]: {
      [topic: string]: string[];
    };
  } = {
    Beginner: {
      'Web Development': ['HTML', 'CSS', 'JavaScript'],
      'Computer Science': ['Python', 'Bash'],
      'Artificial Intelligence': ['Python', 'Bash'],
      'Game Development': ['Python', 'HTML', 'CSS'],
      'Data Visualization': ['Python', 'HTML', 'CSS'],
      'Data Science': ['Python', 'Bash'],
      'Web Design': ['HTML', 'CSS'],
      'Machine Learning': ['Python'],
      'Mobile Development': ['HTML', 'CSS', 'JavaScript'],
      'Not sure yet': ['HTML', 'CSS', 'Python', 'JavaScript'],
    },
    Intermediate: {
      'Web Development': ['HTML', 'CSS', 'JavaScript', 'Git'],
      'Computer Science': ['Python', 'SQL', 'Bash', 'Algorithms'],
      'Artificial Intelligence': ['Python', 'SQL', 'Machine Learning Algorithms'],
      'Game Development': ['Python', 'JavaScript', 'Bash'],
      'Data Visualization': ['Python', 'SQL', 'JavaScript (D3.js)'],
      'Data Science': ['Python', 'SQL', 'Bash', 'Data Structures'],
      'Web Design': ['HTML', 'CSS', 'JavaScript', 'Design Tools'],
      'Machine Learning': ['Python', 'SQL', 'Machine Learning Algorithms'],
      'Mobile Development': ['HTML', 'CSS', 'JavaScript', 'React Native'],
      'Not sure yet': ['Python', 'JavaScript', 'SQL', 'Git'],
    },
    Advanced: {
      'Web Development': ['HTML', 'CSS', 'JavaScript', 'SQL', 'Ruby', 'Git'],
      'Computer Science': ['Python', 'SQL', 'Bash', 'Algorithms', 'Git'],
      'Artificial Intelligence': ['Python', 'SQL', 'Bash', 'Machine Learning Algorithms', 'Data Structures'],
      'Game Development': ['Python', 'Ruby', 'JavaScript', 'Bash'],
      'Data Visualization': ['Python', 'SQL', 'JavaScript (D3.js)', 'HTML/CSS'],
      'Data Science': ['Python', 'SQL', 'Bash', 'Data Structures', 'Statistics'],
      'Web Design': ['HTML', 'CSS', 'JavaScript', 'Bash', 'Design Tools'],
      'Machine Learning': ['Python', 'SQL', 'Bash', 'Machine Learning Algorithms', 'Data Structures'],
      'Mobile Development': ['HTML', 'CSS', 'JavaScript', 'React Native', 'Bash'],
      'Not sure yet': ['Python', 'SQL', 'JavaScript', 'Git', 'Bash'],
    },
  };

  // Function to get skills based on selections
  const getSkills = (): string[] => {
    const level = selections.step3;
    const topic = selections.step1;

    if (level && topic && skillsMapping[level] && skillsMapping[level][topic]) {
      return skillsMapping[level][topic];
    } else {
      return [];
    }
  };

  const benefits = [
    'Personalize your daily study pace to fit your schedule and goals.',
    'Learn by doing with interactive, hands-on coding lessons.',
    'Access personalized help and office hours for expert guidance.',
    'Courses designed by developers to prepare you for real-world jobs.',
  ];

  // Progress Indicator Component
  const renderProgressIndicator = () => {
    const steps = [1, 2, 3];
    return (
      <div className="flex justify-center mb-6">
        {steps.map((step) => (
          <div key={step} className="mx-2">
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                currentStep >= step ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-600'
              }`}
            >
              {step}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Function to render the current step
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div style={stepStyle}>
            <h1 className="text-3xl md:text-4xl font-bold text-center mb-4">
              Find What Works for You
            </h1>
            <p className="text-base md:text-lg text-center mb-8">
              Answer 3 quick questions to get recommendations that match your interests.
            </p>
            <h3 className="text-2xl font-bold mb-4 text-center">
              What are you looking to learn?
            </h3>
            <div className="flex flex-wrap -mx-2">
              {step1Options.map((option) => (
                <div key={option} className="w-full md:w-1/2 px-2 mb-4">
                  <button
                    className="bg-white w-full border border-gray-300 p-4 rounded hover:bg-blue-100 focus:outline-none"
                    onClick={() => handleStep1Selection(option)}
                  >
                    {option}
                  </button>
                </div>
              ))}
            </div>
            <p className="text-base md:text-lg text-center mt-4">
              Explore a variety of courses that fit into your limited free time.
            </p>
          </div>
        );
      case 2:
        return (
          <div style={stepStyle}>
            <h3 className="text-2xl font-bold mb-4 text-center">
              What do you want to achieve?
            </h3>
            <div className="flex flex-wrap -mx-2">
              {step2Options.map((option) => (
                <div key={option} className="w-full md:w-1/2 px-2 mb-4">
                  <button
                    className="bg-white w-full border border-gray-300 p-4 rounded hover:bg-blue-100 focus:outline-none"
                    onClick={() => handleStep2Selection(option)}
                  >
                    {option}
                  </button>
                </div>
              ))}
            </div>
            <p className="text-base md:text-lg text-center mt-4">
              Set your goals and increase your success rate. A clear objective makes you 40% more likely to stick with it.
            </p>
            <div className="mt-4">
              <button
                className="text-blue-600 hover:underline focus:outline-none"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={stepStyle}>
            <h3 className="text-2xl font-bold mb-4 text-center">
              How much coding experience do you have?
            </h3>
            <div className="-mx-2">
              {step3Options.map((option) => (
                <div key={option.level} className="w-full md:w-1/2 px-2 mb-4 mx-auto">
                  <button
                    className="bg-white w-full border border-gray-300 p-4 rounded hover:bg-blue-100 focus:outline-none text-left"
                    onClick={() => handleStep3Selection(option.level)}
                  >
                    <strong>{option.level}</strong>
                    <p className="mt-2">{option.description}</p>
                  </button>
                </div>
              ))}
            </div>
            <p className="text-base md:text-lg text-center mt-4">
              No matter your level, our hands-on learning environment is designed to help you succeed.
            </p>
            <div className="mt-4">
              <button
                className="text-blue-600 hover:underline focus:outline-none"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div style={stepStyle}>
            <p className="text-xl text-center">
              Finding the perfect courses based on your goals...
            </p>
            <div className="sk-cube-grid">
              <style>
                {`
                  .sk-cube-grid {
                    width: 40px;
                    height: 40px;
                    margin: 100px auto;
                  }

                  .sk-cube-grid .sk-cube {
                    width: 33%;
                    height: 33%;
                    background-color: #333;
                    float: left;
                    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
                            animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
                  }
                  .sk-cube-grid .sk-cube1 {
                    -webkit-animation-delay: 0.2s;
                            animation-delay: 0.2s; }
                  .sk-cube-grid .sk-cube2 {
                    -webkit-animation-delay: 0.3s;
                            animation-delay: 0.3s; }
                  .sk-cube-grid .sk-cube3 {
                    -webkit-animation-delay: 0.4s;
                            animation-delay: 0.4s; }
                  .sk-cube-grid .sk-cube4 {
                    -webkit-animation-delay: 0.1s;
                            animation-delay: 0.1s; }
                  .sk-cube-grid .sk-cube5 {
                    -webkit-animation-delay: 0.2s;
                            animation-delay: 0.2s; }
                  .sk-cube-grid .sk-cube6 {
                    -webkit-animation-delay: 0.3s;
                            animation-delay: 0.3s; }
                  .sk-cube-grid .sk-cube7 {
                    -webkit-animation-delay: 0s;
                            animation-delay: 0s; }
                  .sk-cube-grid .sk-cube8 {
                    -webkit-animation-delay: 0.1s;
                            animation-delay: 0.1s; }
                  .sk-cube-grid .sk-cube9 {
                    -webkit-animation-delay: 0.2s;
                            animation-delay: 0.2s; }

                  @-webkit-keyframes sk-cubeGridScaleDelay {
                    0%, 70%, 100% {
                      -webkit-transform: scale3D(1, 1, 1);
                              transform: scale3D(1, 1, 1);
                    } 35% {
                      -webkit-transform: scale3D(0, 0, 1);
                              transform: scale3D(0, 0, 1);
                    }
                  }

                  @keyframes sk-cubeGridScaleDelay {
                    0%, 70%, 100% {
                      -webkit-transform: scale3D(1, 1, 1);
                              transform: scale3D(1, 1, 1);
                    } 35% {
                      -webkit-transform: scale3D(0, 0, 1);
                              transform: scale3D(0, 0, 1);
                    }
                  }
                `}
              </style>
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        );
      case 5:
        const skills = getSkills();
        return (
          <div style={stepStyle}>
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
              We picked courses just for you
            </h2>
            <h3 className="text-2xl font-bold mb-4 text-center">
              Skills that match your interests
            </h3>
            <div className="flex justify-center flex-wrap mb-6">
              {skills.map((skill, index) => (
                <span key={skill} className="bg-[#151924] text-white px-3 py-1 rounded-full m-1">
                  {skill}
                  {index < skills.length - 1}
                </span>
              ))}
            </div>
            <h3 className="text-2xl font-bold mb-4 text-center">
              Discover what’s possible with Hourandcode
            </h3>
            <div className="mb-6">
              {benefits.map((benefit, index) => (
                <div key={index} className="mb-2 flex justify-center">
                <div className="flex items-center text-left">
                  <span className="bg-blue-600 text-white rounded-full h-6 w-6 flex items-center justify-center mr-2">
                    ✔
                  </span>
                  <span className="text-base md:text-lg">{benefit}</span>
                </div>
              </div>
              ))}
            </div>
            <p className="text-base md:text-lg text-center mb-4">
              <b>Create a free account to see your matches</b>
            </p>
            <div className="flex justify-center mb-4">
              <button
                className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 focus:outline-none"
                onClick={() => {
                  posthog.capture('Quiz CTA Clicked');
                  window.location.href = 'https://buy.stripe.com/9AQ3dTajhgCDgIocMN';
                }}
              >
                Start 7-day Free Trial
              </button>
            </div>
            <p className="text-base md:text-lg text-center">
              or <a onClick={() => { posthog.capture('Quiz Link Clicked');}} style={{color: "#2664ec"}} href="https://buy.stripe.com/4gwcOt775gCD8bSdQQ">$359.88/year with 14-day money-back guarantee</a>
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="container px-4 mx-auto py-12 md:py-16 lg:py-20">
        {/* Start Over link */}
        <div className="flex justify-end mb-4">
          <button
            className="text-blue-600 hover:underline focus:outline-none flex items-center"
            onClick={handleStartOver}
          >
            {/* You can add an icon here if needed */}
            {currentStep > 1 && <>Start Over</>}
          </button>
        </div>
        <div className="space-y-8">
          {/* Progress Indicator */}
          {currentStep <= 3 && renderProgressIndicator()}
          {/* Render Current Step */}
          {renderStep()}
        </div>
      </div>
    </div>
  );
};

export default QuizComponent;
